<template>
	<div class="page-container">
		<nav-bar title="继续教育在线报名" :fixed="true" id="navbar" right-text="去学习" @click-right="onClickRight"></nav-bar>
		<!-- 报名表单 -->
		<div class="form-area" v-if="!isSignupSuccess && !isSignupConfirm">
			<van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
				注意：目前仅支持晋中市区县学员报名。请您第一步输入身份证号码后点击[查询]按钮获取资格证信息！
			</van-notice-bar>

			<van-form @submit="onConfirm" ref="formData" action="">
				<div class="title" v-if="agentId > 0">
					<span style="color: #888;">代理编号：{{this.agentId}}</span>
				</div>
				<van-field
					v-model="form.certNum"
					type="search"
					name="certNum"
					center
					clearable
					label="身份证号"
					placeholder="请输入晋中市区县身份证号"
					maxlength="18"
					:rules="[{ required: true, message: '请填写身份证号码' }]"
				>
					<template #button>
						<van-button
							size="small"
							type="primary"
							@click="getStudentInfo"
							native-type="button"
						>查询
						</van-button>
					</template>
				</van-field>
				<div v-if="searchResult">
					<!-- 查询资格证后出现 -->
					<van-field
						v-model="form.cyzgzh"
						name="zgzh"
						label="资格证号"
						placeholder="资格证号码"
						readonly
					/>
					<van-field
						v-model="form.xm"
						name="xm"
						label="真实姓名"
						placeholder="真实姓名"
						readonly
					/>
					<van-field name="xb" label="性别">
						<template #input>
							<van-radio-group v-model="form.xb" direction="horizontal" disabled>
								<van-radio name="1">男</van-radio>
								<van-radio name="2">女</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field
						v-model="form.sjhm"
						name="sjhm"
						type="tel"
						label="手机号码"
						placeholder="手机号码"
						maxlength="11"
					/>
					<van-field
						v-model="form.csny"
						name="csny"
						label="出生日期"
						placeholder="出生日期"
						readonly
					/>
					<van-field
						v-model="form.zz"
						name="zz"
						label="住址"
						placeholder="住址"
						readonly
					/>
					<div class="title">
						<span style="color: #409EFF;">请如实填写以下报名信息</span>
					</div>
					<van-field
						v-model="form.zjcx"
						name="zjcx"
						label="准驾车型"
						placeholder="请填写准驾车型，如A1A2"
						:rules="[{ required: true, message: '请填写准驾车型' }]"
					/>
					<!-- 可选课程 -->
					<van-field
						readonly
						clickable
						name="pxcx"
						v-model="form.pxcx"
						label="可选课程"
						placeholder="点击选择可选课程"
						@click="showPicker = true"
						:rules="[{ required: true, message: '请选择学习课程' }]"
					/>
					<van-popup v-model="showPicker" position="bottom">
						<van-picker
							show-toolbar
							:columns="columns"
							@confirm="onConfirmPicker"
							@cancel="showPicker = false"
							value-key="text"
						/>
					</van-popup>
					<!-- 所在地区 -->
					<van-field
						readonly
						clickable
						name="szdq"
						v-model="form.szdq"
						label="所在地区"
						placeholder="点击选择所在地区"
						@click="showPickerDq = true"
						style="display: none"
					/>
					<van-popup v-model="showPickerDq" position="bottom">
						<van-picker
							show-toolbar
							:columns="columnsDq"
							@confirm="onConfirmPickerDq"
							@cancel="showPickerDq = false"
							value-key="text"
							:default-index="0"
						/>
					</van-popup>
					<!-- 审验地区 -->
					<van-field
						readonly
						clickable
						name="sydq"
						v-model="form.sydq"
						label="审验地区"
						placeholder="点击选择从业资格证审验地区"
						@click="showPickerSy = true"
						:rules="[{ required: true, message: '请选择从业资格证审验地区' }]"
					/>
					<van-popup v-model="showPickerSy" position="bottom">
						<van-picker
							show-toolbar
							:columns="columnsSy"
							@confirm="onConfirmPickerSy"
							@cancel="showPickerSy = false"
							value-key="text"
							:default-index="0"
						/>
					</van-popup>
					<!-- 培训年度 -->
					<van-field
						readonly
						clickable
						name="pxnd"
						v-model="form.pxnd"
						label="培训年度"
						placeholder="点击选择培训年度"
						@click="showPickerNd = true"
						:rules="[{ required: true, message: '请选择培训年度' }]"
					/>
					<van-popup v-model="showPickerNd" position="bottom">
						<van-picker
							show-toolbar
							:columns="columnsNd"
							@confirm="onConfirmPickerNd"
							@cancel="showPickerNd = false"
							value-key="text"
							:default-index="0"
						/>
					</van-popup>
					<div class="title">
						<span style="color: #E10900; font-weight: bold;">请您认真选择从业资格证审验地区和培训年度，<br>如果选错，学习将无效。</span>
					</div>
					<div style="margin: 16px">
						<van-button round block type="info" native-type="submit">下一步</van-button>
					</div>
				</div>
			</van-form>
		</div>

		<!-- 报名信息确认 -->
		<div class="confirm-area" v-if="isSignupConfirm">
			<div class="checked-plan">
				<van-cell-group title="请确认您的报名信息" inset>
					<van-cell title="真实姓名" :value="form.xm" />
					<van-cell title="身份证号" :value="form.certNum" />
					<van-cell title="性别" :value="form.xb==='1'?'男':'女'" />
					<van-cell title="手机号码" :value="form.sjhm" />
					<van-cell title="资格证号" :value="form.cyzgzh" />
					<van-cell title="准驾车型" :value="form.zjcx" />
					<van-cell title="报名课程" :value="form.pxcx" />
					<van-cell title="培训年度" :value="form.pxnd" />
					<van-cell title="所在地区" :value="form.szdq" style="display: none" />
					<van-cell title="审验地区" :value="form.sydq" />
					<div style="margin: 16px; text-align: center">
						<van-button round plain type="info" native-type="button" @click="onModify"> 修改 </van-button>&nbsp;
						<van-button round type="info" native-type="button" @click="onSubmit"> 确认提交 </van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- 报名成功 -->
		<div class="checked-area" v-if="isSignupSuccess">
			<van-icon name="checked" color="#67C23A" size="80"/>
			<div><span class="checked-msg">恭喜您，报名成功！</span></div>
			<div class="checked-plan">
				<van-cell-group title="报名信息" inset>
					<van-cell title="真实姓名" :value="form.xm" />
					<van-cell title="身份证号" :value="form.certNum" />
					<van-cell title="性别" :value="form.xb==='1'?'男':'女'" />
					<van-cell title="手机号码" :value="form.sjhm" />
					<van-cell title="资格证号" :value="form.cyzgzh" />
					<van-cell title="准驾车型" :value="form.zjcx" />
					<van-cell title="报名课程" :value="form.pxcx" />
					<van-cell title="培训年度" :value="form.pxnd" />
					<van-cell title="所在地区" :value="form.szdq" style="display: none" />
					<van-cell title="审验地区" :value="form.sydq" />
					<div style="margin: 16px">
						<van-button
							round
							block
							type="info"
							native-type="button"
							@click="gotoStudy"
						>点击进入在线教育平台，开始学习吧！
						</van-button>
					</div>
				</van-cell-group>
			</div>
		</div>

		<!-- loading 遮罩层 -->
		<van-overlay :show="showLoading" color="#1989fa">
			<div class="wrapper">
				<div class="block">
					<van-loading size="24px" vertical>
						<span style="color:#4994df">{{ title || '提交中···' }}</span>
					</van-loading>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {NavBar, Toast} from "vant";
	import {getStudentCerInfo, pushSignupInfo} from "../../api/user";

	export default {
		name: "signup-shuangpu-push",
		components: {NavBar},
		data() {
			return {
				agentId: 0,
				title: '',
				showLoading: false,
				isSignupSuccess: false,
				isSignupConfirm: false,
				searchResult: false,
				showPicker: false,
				showPickerNd: false,
				showPickerDq: false,
				showPickerSy: false,
				form: {
					pxnd: '',
					pxnd_value: '',
				},
				columns: [
					{text: '道路旅客运输驾驶员继续教育', value: 'A1-'},
					{text: '道路货物运输驾驶员继续教育', value: 'B2-'},
					{text: '危险货物道路运输从业人员继续教育', value: 'D-'}
				],
				columnsNd: [
					{text: '2022年度', value: '2022'}
				],
				columnsDq: [
					{text: '晋中市', value: '140701'},
					{text: '榆次区', value: '140702'},
					{text: '太谷区', value: '140703'},
					{text: '榆社县', value: '140721'},
					{text: '左权县', value: '140722'},
					{text: '和顺县', value: '140723'},
					{text: '昔阳县', value: '140724'},
					{text: '寿阳县', value: '140725'},
					{text: '祁县', value: '140727'},
					{text: '平遥县', value: '140728'},
					{text: '灵石县', value: '140729'},
					{text: '介休市', value: '140781'}
				],
				columnsSy: [
					// {text: '晋中市', value: '140701'},
					{text: '榆次区', value: '140702'},
					{text: '太谷区', value: '140703'},
					// {text: '榆社县', value: '140721'},
					// {text: '左权县', value: '140722'},
					// {text: '和顺县', value: '140723'},
					// {text: '昔阳县', value: '140724'},
					// {text: '寿阳县', value: '140725'},
					{text: '祁县', value: '140727'},
					{text: '平遥县', value: '140728'},
					// {text: '灵石县', value: '140729'},
					{text: '介休市', value: '140781'}
				]
			}
		},
		mounted() {
			const {agent} = this.$route.query;
			if(typeof (agent) !== 'undefined') {
				this.agentId = agent;
			}
		},
		methods: {
			getStudentInfo() {
				if(typeof(this.form.certNum) === 'undefined' || this.form.certNum === '' || this.form.certNum == null) {
					Toast.fail("请您输入身份证号码");
					return;
				}
				this.showLoading = true;
				getStudentCerInfo({
					"certNum": this.form.certNum
				})
					.then((res) => {
						if (res) {
							this.form.cyzgzh = res.cyzgzh;
							this.form.xm = res.xm;
							this.form.xb = res.xb;
							this.form.sjhm = res.sjhm;
							this.form.csny = res.csny;
							this.form.zz = res.zz;

							if(res.ssjg.substring(0, 4) !== '1407') {
								Toast.fail("抱歉，目前仅支持晋中市辖区学员报名！");
								return;
							}

							let lb = res.cyzglb;
							if(lb === '001') {
								this.columns = [
									{text: '道路旅客运输驾驶员继续教育', value: 'A1-'}
								]
							} else if(lb === '002') {
								this.columns = [
									{text: '道路货物运输驾驶员继续教育', value: 'B2-'}
								]
							} else if(lb === '003') {
								this.columns = [
									{text: '道路旅客运输驾驶员继续教育', value: 'A1-'},
									{text: '道路货物运输驾驶员继续教育', value: 'B2-'}
								]
							} else if(lb === '101') {
								this.columns = [
									{text: '危险货物道路运输从业人员继续教育', value: 'D-'}
								]
							} else if(lb === '104') {
								this.columns = [
									{text: '道路旅客运输驾驶员继续教育', value: 'A1-'},
									{text: '危险货物道路运输从业人员继续教育', value: 'D-'}
								]
							} else if(lb === '105') {
								this.columns = [
									{text: '道路货物运输驾驶员继续教育', value: 'B2-'},
									{text: '危险货物道路运输从业人员继续教育', value: 'D-'}
								]
							} else if(lb === '106') {
								this.columns = [
									{text: '道路旅客运输驾驶员继续教育', value: 'A1-'},
									{text: '道路货物运输驾驶员继续教育', value: 'B2-'},
									{text: '危险货物道路运输从业人员继续教育', value: 'D-'}
								]
							} else if(lb === '108') {
								this.columns = [
									{text: '危险货物道路运输从业人员继续教育', value: 'D-'}
								]
							} else if(lb === '112') {
								this.columns = [
									{text: '危险货物道路运输从业人员继续教育', value: 'D-'}
								]
							} else {
								Toast.fail("未知的从业资格编码");
								return;
							}

							// 默认审验地区
							let areaCode = res.ssjg === '140700' ? '140701' : res.ssjg;
							if(areaCode === '140726') {
								areaCode = '140703';
							}
							this.form.szdq = this.getAreaTitle(areaCode);
							this.form.szdq_value = areaCode;
							//this.form.sydq = this.form.szdq;
							//this.form.sydq_value = areaCode;

							this.columnsNd = this.getPxnd(areaCode);

							// 显示搜索结果
							this.searchResult = true;
						}
						this.showLoading = false;
					})
					.catch(() => {
						this.showLoading = false;
					});
			},
			onConfirm() {
				if(typeof(this.form.certNum) === 'undefined' || this.form.certNum === '' || this.form.certNum == null) {
					return;
				}
				if(!this.searchResult) {
					this.getStudentInfo();
				} else {
					if (!this.form.sjhm || this.form.sjhm === '') {
						Toast.fail("请您输入手机号码");
						return;
					}
					if(!(/^1[3456789]\d{9}$/.test(this.form.sjhm))) {
						Toast.fail("请您输入正确格式的手机号码");
						return;
					}
					this.isSignupConfirm = true;
				}
			},
			onModify() {
				this.isSignupConfirm = false;
			},
			onSubmit() {
				if (!this.form.sjhm || this.form.sjhm === '') {
					Toast.fail("请您输入手机号码");
					return;
				}
				if(!(/^1[3456789]\d{9}$/.test(this.form.sjhm))) {
					Toast.fail("请您输入正确格式的手机号码");
					return;
				}
				if (!this.form.zjcx) {
					Toast.fail("请您输入准驾车型");
					return;
				}
				if (!this.form.pxcx) {
					Toast.fail("请您选择报名课程");
					return;
				}
				if (!this.form.pxnd) {
					Toast.fail("请您选择培训年度");
					return;
				}
				if (!this.form.sydq) {
					Toast.fail("请您选择从业资格证审验地区");
					return;
				}
				//if (!this.form.szdq) {
					this.form.szdq = this.form.sydq;
					this.form.szdq_value = this.form.sydq_value;
				//}
				this.showLoading = true;
				pushSignupInfo({
					certNum: this.form.certNum,
					sjhm: this.form.sjhm,
					zjcx: this.form.zjcx,
					pxcx: this.form.pxcx_value,
					pxnd: this.form.pxnd_value,
					szdq: this.form.szdq_value,
					sydq: this.form.sydq_value,
					agent: this.agentId,
				})
				.then(() => {
					this.isSignupSuccess = true;
					this.isSignupConfirm = false;
					this.showLoading = false;
				})
				.catch(() => {
					this.showLoading = false;
				});
			},
			onConfirmPicker(value) {
				this.form.pxcx = value.text;
				this.form.pxcx_value = value.value;
				this.showPicker = false;
			},
			onConfirmPickerNd(value) {
				this.form.pxnd = value.text;
				this.form.pxnd_value = value.value;
				this.showPickerNd = false;
			},
			onConfirmPickerDq(value) {
				this.form.szdq = value.text;
				this.form.szdq_value = value.value;
				this.showPickerDq = false;
			},
			onConfirmPickerSy(value) {
				this.form.sydq = value.text;
				this.form.sydq_value = value.value;
				this.columnsNd = this.getPxnd(value.value);
				this.showPickerSy = false;
			},
			gotoStudy() {
				window.location.href = "/index.html#/?appNo=APP02";
			},
			getAreaTitle(areaCode) {
				for(let i=0; i<this.columnsDq.length; i++) {
					if(this.columnsDq[i].value === areaCode) {
						return this.columnsDq[i].text;
					}
				}
			},
			getPxnd(areaCode) {
				this.form.pxnd = '';
				this.form.pxnd_value = '';
				if(areaCode === '140781') {
					//介休
					return [
						{text: '2022年度', value: '2022'},
						{text: '2023年度', value: '2023'},
					]
				}
				return [
					{text: '2022年度', value: '2022'}
				];
			},
			onClickRight() {
				this.gotoStudy();
			},
		}
	}
</script>

<style scoped>
	#navbar {
		background-color: #409eff;
	}

	#navbar /deep/ .van-ellipsis {
		color: #fff !important;
	}

	#navbar /deep/ .van-nav-bar__text {
		color: #fff !important;
	}

	.form-area {
		margin-top: 46px;
	}

	.title {
		margin: 0;
		padding: 16px 16px 16px;
		color: rgba(69, 90, 100, 0.6);
		font-weight: normal;
		font-size: 14px;
		line-height: 16px;
	}

	.checked-area {
		margin-top: 90px;
		text-align: center;
	}

	.confirm-area {
		text-align: center;
	}

	.checked-msg {
		font-size: 14px;
		color: #67c23a;
	}

	.checked-plan {
		text-align: left;
		margin-top: 40px;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.block {
		width: 120px;
		height: 120px;
		border-radius: 15px;
		background-color: #fff;
		padding-top: 32px;
	}
</style>
